import * as React from "react";
import Layout from "../../../components/layout/Layout.component";
import Button from "../../../components/button/Button.component";
import * as style from "./Blackboard.style.module.scss";

const Blackboard = () => {
  const canvasRef = React.useRef(null);
  const ctxRef = React.useRef(null);
  const [isDrawing, setIsDrawing] = React.useState(false);
  const [lineSize, setLineSize] = React.useState(40);
  const [lineColor, setLineColor] = React.useState("#000000");
  const [windowWidth, setwindowWidth] = React.useState("");

  if (typeof window !== "undefined" && windowWidth === "") {
    setwindowWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setwindowWidth(window.innerWidth);
    });
  }

  React.useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth * 2;
    canvas.height = window.innerHeight * 2;
    canvas.style.width = `${window.innerWidth}px`;
    canvas.style.height = `${window.innerHeight}px`;

    const ctx = canvas.getContext("2d");
    ctx.scale(2, 2);
    ctxRef.current = ctx;
  }, [windowWidth]);

  React.useEffect(() => {
    ctxRef.current.lineWidth = lineSize;
    ctxRef.current.lineCap = "round";
    ctxRef.current.strokeStyle = lineColor;
  }, [lineSize, lineColor, windowWidth]);

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    ctxRef.current.beginPath();
    ctxRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const finishDrawing = () => {
    ctxRef.current.closePath();
    setIsDrawing(false);
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = nativeEvent;
    ctxRef.current.lineTo(offsetX, offsetY);
    ctxRef.current.stroke();
  };

  const downloadImage = () => {
    const url = canvasRef.current.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "amazingPicture.png";
    link.href = url;
    link.click();
  };

  return (
    <Layout>
      <div className={style.canvas}>
        <canvas
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={finishDrawing}
          ref={canvasRef}
          style={{
            backgroundColor: "#FFFFFF",
          }}
        />
        <div className={style.input_values}>
          <input
            type="color"
            name="lineColor"
            id=""
            value={lineColor}
            onChange={({ nativeEvent }) => {
              setLineColor(nativeEvent.target.value);
            }}
          />
          <label htmlFor="lineColor">Line Color</label>
          <label htmlFor="lineSize">
            <input
              type="range"
              name="lineSize"
              id=""
              min="5"
              max="40"
              step="5"
              value={lineSize}
              onChange={({ nativeEvent }) => {
                setLineSize(nativeEvent.target.value);
              }}
            />{" "}
            Line Size
          </label>
          <Button
            onClick={() => {
              ctxRef.current.clearRect(
                0,
                0,
                canvasRef.current.width,
                canvasRef.current.height
              );
            }}
          >
            Clear All
          </Button>
          <Button
            onClick={() => {
              downloadImage();
            }}
          >
            Download
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Blackboard;

export function Head() {
  return <title>Blackboard</title>;
}
